.entry-content {
  scroll-padding-top: 6rem;
  h2, h3, h4, p {
    > a {
      @apply text-elephant-500;
    }
  }
}

body *[id] {
  scroll-margin-top: 6rem;
}

.hljs-symbol {
  white-space: pre;
  @apply pl-5;
  &::after{
    content: "\A";
    white-space: pre;
  }
}

.wp-block-image {
  &.size-thumbnail {
    width: 150px;
    height: 150px;
  }

  &.size-medium {
    width: 300px;
    height: 300px;
  }

  &.size-full {
    width: 1024px;
    height: 1024px;
  }

  &.is-resized {
    width: auto;
    height: auto;
    &.size-thumbnail {
      min-width: 150px;
      min-height: 150px;
    }
  
    &.size-medium {
      width: auto;
      max-width: 300px;
      max-height: 300px;
    }
  
    &.size-full {
      width: auto;
      height: auto;
      max-width: 1024px;
      max-height: 1024px;
    }
  }
}
.desktop-only {
  @apply hidden lg:visible lg:block;
}

.mobile-only {
  @apply lg:hidden;
}

.next-image-container img {
  height: auto !important;
  max-width: 100% !important;
  vertical-align: bottom !important;
}

.cover {
  transform: translateY(-100%);
}

.is-layout-flex {
  display: flex;
  flex-wrap: wrap;

  &.is-vertical {
    flex-direction: column;
    align-items: center;
    &.is-content-justification-center {
      align-items: center;
    }
  }  
}

.wp-block-columns.is-layout-flex {
  gap: 2em;
}

.entry-content {
  margin-top: 0;
}

.entry-content > * {
  margin-top: 0;
  margin-bottom: 0;
}

.wp-block-columns {
  margin-bottom: 0;
}

.wp-block-group > * {
    margin-left: auto;
    margin-right: auto;
}
