@use "sass:math";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@wordpress/base-styles/colors";
@import "~@wordpress/base-styles/variables";
@import "~@wordpress/base-styles/mixins";
@import "~@wordpress/base-styles/breakpoints";
@import "~@wordpress/base-styles/animations";
@import "~@wordpress/base-styles/z-index";
@import '~@wordpress/block-library/src/style.scss';
@import '~@wordpress/block-library/src/theme.scss';

@import './style.scss';

@import './components.scss'; 
@import './misc.scss';